import { Edit, Error, Help, HighlightOff, InfoOutlined, KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight, ManageHistory, PlayArrow, PublishedWithChanges, Stop, Support } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Link, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridColDef, GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid-pro";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { useMutation, useQuery } from "@tanstack/react-query";
import { sortBy } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchGet, fetchPost, fetchPut, hasRole, isMobile, LayoutContext, newGuid, persistStorage } from "wcz-layout";
import CreateDailyWorkloadApplicationDialog from "../components/dailyWorkload/CreateDailyWorkloadApplicationDialog";
import DailyWorkloadApplicationDialog from "../components/dailyWorkload/DailyWorkloadApplicationDialog";
import EditDailyWorkloadApplicationDialog from "../components/dailyWorkload/EditDailyWorkloadApplicationDialog";
import StartSupportDialog from "../components/dailyWorkload/StartSupportDialog";
import ApplicationData from "../models/ApplicationData";
import { ApprovalStatus } from "../models/ApprovalStatus";
import Break from "../models/Break";
import DailyWorkloadApplication from "../models/DailyWorkloadApplication";
import DailyWorkloadModel from "../models/DailyWorkloadModel";
import Head from "../models/Head";
import HeadcountSummaryModel from "../models/HeadcountSummaryModel";
import { apiAnetUrl, apiUrl } from "../utils/BaseUrl";
import WarningDialog from "../components/dailyWorkload/WarningDialog";
import { ProjectSettingsContext } from "../contexts/ProjectSettingsContext";
import Policy from "../utils/Policy";

interface CustomToolbarProps {
    month: number,
    year: number,
    setYearAndMonth: (year: number, month: number) => void,
    showStartAndEndButtons: boolean,
    lockedForEditing: boolean,
    employeeId: string,
    employee: HeadcountSummaryModel,
    workingStatus: number,
    currentBreak: Break,
    handleClickStart: () => void,
    handleClickEnd: () => void,
    handleClickSupport: () => void,
    disableFastClicking: boolean,
    missingAssignment: boolean
}

function CustomToolbar(props: CustomToolbarProps) {
    return (
        <GridToolbarContainer sx={{ pb: "15px" }}>
            <Grid container>
                <Grid item xs={8}>
                    <Typography variant="h6">{props.employee.firstName} {props.employee.lastName} ({props.employeeId}) - {props.employee.position} at {props.employee.departmentId}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "end" }}>
                    
                    {
                        moment({ year: props.year, month: props.month - 1 }) > moment("202209") &&
                        <IconButton onClick={() => {
                                var newYear = props.year;
                                var newMonth = props.month - 1;

                                if (newMonth === 0) {
                                    newYear -= 1;
                                    newMonth = 12;
                                }

                                props.setYearAndMonth(newYear, newMonth);
                        }}>
                            <KeyboardArrowLeft />
                        </IconButton>
                    }
                    <DatePicker
                        views={["year", "month"]}
                        openTo="month"
                        minDate={moment("202209")}
                        maxDate={moment()}
                        value={moment({year: props.year, month: props.month - 1})}
                        onChange={(newValue) => {
                            if (newValue) {
                                props.setYearAndMonth(newValue.year(), newValue.month() + 1);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} size="small" sx={{ width: "220px" }} helperText={null} />}
                    />
                    {
                        moment({ year: props.year, month: props.month - 1 }) < moment().add(-1, "month") &&
                        <IconButton onClick={() => {
                                var newYear = props.year;
                                var newMonth = props.month + 1;

                                if (newMonth === 13) {
                                    newYear += 1;
                                    newMonth = 1;
                                }

                                props.setYearAndMonth(newYear, newMonth);
                        }}>
                            <KeyboardArrowRight />
                        </IconButton>
                    }
                    {
                        moment({ year: props.year, month: props.month - 1 }).get("month") !== moment().get("month") &&
                        moment().add(-2, "month") > moment({ year: props.year, month: props.month - 1 }) &&
                        <IconButton onClick={() => props.setYearAndMonth(moment().year(), moment().month() + 1)}>
                            <KeyboardDoubleArrowRight />
                        </IconButton>
                    }
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    {
                        props.missingAssignment ?
                            <Typography color="error">Missing assignment!</Typography>
                            :
                            <Typography>Assigned to project: {props.employee.project} / {props.employee.line} / {props.employee.station}</Typography>
                    }                    
                </Grid>
                <Grid item xs={8} sx={{ textAlign: "end", pt: "5px" }}>
                    {
                        (!isMobile && props.showStartAndEndButtons && !props.lockedForEditing && (props.workingStatus === 0 || props.workingStatus === 2)) &&
                        <Button disabled={props.disableFastClicking || props.missingAssignment}  color="primary" size="small" startIcon={<PlayArrow />} sx={{ ml: "5px" }} onClick={props.handleClickStart}>{props.workingStatus === 2 ? `Return to ${props.employee.station}` : `Start at ${props.employee.station}`}</Button>
                    }
                    {
                        (!isMobile && props.showStartAndEndButtons && !props.lockedForEditing && (props.workingStatus === 1 || props.workingStatus === 2)) &&
                        <Button disabled={props.disableFastClicking || props.missingAssignment} color="primary" size="small" startIcon={<Stop />} sx={{ ml: "5px" }} onClick={props.handleClickEnd}>End</Button>
                    }
                    {
                        (!isMobile && props.showStartAndEndButtons && !props.lockedForEditing) &&
                        <Button disabled={props.disableFastClicking || props.missingAssignment} color="primary" size="small" startIcon={<Support />} sx={{ ml: "5px" }} onClick={props.handleClickSupport}>Support</Button>
                    }
                </Grid>
            </Grid>  
            {
                !props.lockedForEditing && props.currentBreak.start &&
                <Grid container>
                        <Grid item xs={10}>
                            <Typography component="span" color="primary">Current break: {`${props.currentBreak.start} - ${props.currentBreak.end}`}</Typography>
                    </Grid>
                </Grid>
            }
        </GridToolbarContainer>
    );
}

function calculateWorkedHours(dailyWorkload: DailyWorkloadModel, breaks: Break[]): string {
    if (!dailyWorkload.start || !dailyWorkload.end) return "";

    let workStart = moment(dailyWorkload.start, "H:mm");
    let workEnd = moment(dailyWorkload.end, "H:mm");

    let workDiff = workEnd.diff(workStart);
    let breakDiff = 0;

    //let breaks: Break[] = breakSchedule.filter(b => b.departmentId === employee.departmentId && b.project === employee.project && b.line === employee.line);
    for (let varBreak of breaks) {
        let breakStart = moment(varBreak.start, "H:mm");
        let breakEnd = moment(varBreak.end, "H:mm");

        // Work start and end time are both in the middle of the break
        if (workStart.isSameOrAfter(breakStart) && workEnd.isSameOrBefore(breakEnd)) {
            workDiff = 0;
            break;
        }

        // #1: The whole break is in the middle of the working time
        if (workStart.isBefore(breakStart) && workEnd.isAfter(breakEnd)) {
            breakDiff += breakEnd.diff(breakStart);
        }
        // #2: The work start is in the middle of the break or is the same as the break start time
        else if (workStart.isBetween(breakStart, breakEnd) || workStart.isSame(breakStart)) {
            breakDiff += breakEnd.diff(workStart);
        }
        // #3: The work end is in the middle of the break or is the same as the break end time
        else if (workEnd.isBetween(breakStart, breakEnd) || workEnd.isSame(breakEnd)) {
            breakDiff += workEnd.diff(breakStart);
        }
    }

    let workAndBreakDiffsInHours = moment.duration(workDiff - breakDiff);
    return moment.utc(workAndBreakDiffsInHours.asMilliseconds()).format("H:mm");
}

function calculateBreaktime(dailyWorkload: DailyWorkloadModel, breaks: Break[]): string {
    if (dailyWorkload.start === null || dailyWorkload.end === null) return "";

    let workStart = moment(dailyWorkload.start, "H:mm");
    let workEnd = moment(dailyWorkload.end, "H:mm");

    //let breaks: Break[] = breakSchedule.filter(b => b.departmentId === employee.departmentId && b.project === employee.project && b.line === employee.line);

    let breakDiff = 0;
    for (let varBreak of breaks) {
        let breakStart = moment(varBreak.start, "H:mm");
        let breakEnd = moment(varBreak.end, "H:mm");

        // Work start and end time are both in the middle of the break
        if (workStart.isSameOrAfter(breakStart) && workEnd.isSameOrBefore(breakEnd)) {
            breakDiff = workEnd.diff(workStart);;
            break;
        }

        // #1: The whole break is in the middle of the working time
        if (workStart.isBefore(breakStart) && workEnd.isAfter(breakEnd)) {
            breakDiff += breakEnd.diff(breakStart);
        }
        // #2: The work start is in the middle of the break or is the same as the break start time
        else if (workStart.isBetween(breakStart, breakEnd) || workStart.isSame(breakStart)) {
            breakDiff += breakEnd.diff(workStart);
        }
        // #3: The work end is in the middle of the break or is the same as the break end time
        else if (workEnd.isBetween(breakStart, breakEnd) || workEnd.isSame(breakEnd)) {
            breakDiff += workEnd.diff(breakStart);
        }
    }

    if (breakDiff === 0) {
        return "";
    }

    let breakDiffInHours = moment.duration(breakDiff);
    return moment.utc(breakDiffInHours.asMilliseconds()).format("H:mm");
}

function calculateOvertime(employee: HeadcountSummaryModel, dailyWorkload: DailyWorkloadModel, breaks: Break[]): string {
    if (!employee.workshiftEnd || employee.workshiftEnd === "00:00") return "";
    if (dailyWorkload.isWeekend || dailyWorkload.isHoliday) return dailyWorkload.worked ?? "";

    let workStart = moment(dailyWorkload.start, "H:mm");
    let workEnd = moment(dailyWorkload.end, "H:mm");
    let workshiftEnd = moment(employee.workshiftEnd, "H:mm");
    let overtime = "";
    if (workEnd.isAfter(workshiftEnd)) {
        if (workStart.isAfter(workshiftEnd)) {
            overtime = moment(workEnd).subtract(moment.duration(dailyWorkload.start)).format("H:mm");
        }
        else {
            overtime = moment(workEnd).subtract(moment.duration(employee.workshiftEnd)).format("H:mm");            
        }

        let breaktimeDuration = 0;
        let overtimeBreaks: Break[] = breaks.filter(b => b.type === "Overtime1" || b.type === "Overtime2");
        overtimeBreaks.forEach((element) => {
            let breakStart = moment(element.start, "H:mm");
            let breakEnd = moment(element.end, "H:mm");
            if (breakStart.isAfter(workStart) && breakEnd.isSameOrBefore(workEnd)) {
                breaktimeDuration += element.durationInMinutes;
            }
        });

        if (breaktimeDuration) {
            overtime = moment(overtime, "H:mm").subtract(moment.duration(breaktimeDuration, "minutes")).format("H:mm");
        }
    }

    return overtime;
}

export default function DailyWorkload() {
    const { user } = useContext(LayoutContext);
    const { headList } = useContext(ProjectSettingsContext);
    const isHead = useMemo(() => headList.some(head => head.employeeId === user?.id) || user.id === "C1107048", [user]);
    const isAdmin = useMemo(() => hasRole(Policy.Admin), [user]);
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState<DailyWorkloadModel[]>([] as DailyWorkloadModel[]);
    const { employeeId } = useParams();
    const [year, setYear] = useState(isHead && persistStorage.get("DatePicker-Year") ? Number(persistStorage.get("DatePicker-Year")) : moment().year());
    const [month, setMonth] = useState(isHead && persistStorage.get("DatePicker-Month") ? Number(persistStorage.get("DatePicker-Month")) : moment().month() + 1);
    const showStartAndEndButtons = !(+year! < moment().year() || +month! <= (moment().month() - 1)) && (user.id === employeeId || isAdmin);
    const lockedForEditing = useMemo(() => {
        const currentMonth = moment().month() + 1;
        const currentYear = moment().year();
        const lastDayForEditingInCurrentMonth = moment().date(10);
        const previousMonth = moment().date(1).subtract(1, "month").month() + 1;
        const previousYear = moment().subtract(1, "year").year();
        const enableEditing = (month === currentMonth && year === currentYear) || (month === previousMonth && year === previousYear && moment().isSameOrBefore(lastDayForEditingInCurrentMonth));
        return !enableEditing;
    }, [year, month]);
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [employee, setEmployee] = useState({} as HeadcountSummaryModel);
    const [headsOfEmployee, setHeadsOfEmployee] = useState<Head[]>([] as Head[]);
    const [employeeBreaks, setEmployeeBreaks] = useState<Break[]>([] as Break[]);
    const [currentBreak, setCurrentBreak] = useState({} as Break);
    const [dailyWorkloadApplications, setDailyWorkloadApplications] = useState<DailyWorkloadApplication[]>([] as DailyWorkloadApplication[]);
    const [workingStatus, setWorkingStatus] = useState(0); // 0 - standby status, 1 - work on assigned project, 2 - support
    const [openStartSupportDialog, setOpenStartSupportDialog] = useState(false);
    const [disableFastClicking, setDisableFastClicking] = useState(false);
    const [missingAssignment, setMissingAssignment] = useState(false);
    const [dayToEdit, setDayToEdit] = useState("");
    const [dailyWorkloadApplicationIdToOpen, setDailyWorkloadApplicationIdToOpen] = useState("");
    const [dailyWorkloadApplicationIdToEdit, setDailyWorkloadApplicationIdToEdit] = useState(""); 



    useQuery<Break[]>(["employeeBreaks"], ({ signal }) => fetchGet(`${apiAnetUrl}/v1/shift/breaks/employeeId/${employeeId}`, signal), {
        enabled: !!employee.employeeId,
        onSuccess: data => setEmployeeBreaks(data)
    });
    const { refetch: fetchHsEmployee } = useQuery<HeadcountSummaryModel>(["hsEmployee"], ({ signal }) => fetchGet(`${apiUrl}/v1/hsemployee/employeeId/${employeeId}`, signal), { enabled: false, onSuccess: data => setEmployee(data) });
    const { refetch: fetchDailyWorkloads } = useQuery<DailyWorkloadModel[]>(["dailyWorkloads", year, month, employeeId], ({ signal }) => fetchGet(`${apiUrl}/v1/dailyworkload/${year}/${month}/${employeeId}`, signal), { enabled: false, onSuccess: data => { /*console.log("Zavolal se fetch Daily Workloads");*/ setRows(data); } });
    const { refetch: fetchDailyWorkloadApps } = useQuery<DailyWorkloadApplication[]>(["dailyWorkloadApps", year, month, employeeId], ({ signal }) => fetchGet(`${apiUrl}/v1/dailyworkloadapplication/${year}/${month}/${employeeId}`, signal), { enabled: false, onSuccess: data => { /*console.log("Zavolal se fetch Applications");*/ setDailyWorkloadApplications(data); } });

    useEffect(() => {
        if (!!employeeId && !!year && !!month) {
            fetchData();               
        }
    }, [employeeId, year, month]);

    const showWarningDialogIfAnyError = useCallback(() => {
        //console.log("zavolala se funkce showErrorDialogIfAnyError()");
        const gridRows = Array.from(apiRef.current.getRowModels().values());
        //console.log("zamceni editovani je: ", lockedForEditing);
        //console.log(`hodnota rows uvnitr funkce: `, rows);
        //console.log("apiRef obsahuje: ", gridRows);
        //console.log(`nactene zadosti: `, dailyWorkloadApplications);
        
        if (gridRows.length === 0) return;
        if (lockedForEditing || isHead) { setShowErrorDialog(false); return; }

        setShowErrorDialog(false);
        let lastRow = gridRows[gridRows.length - 1];

        if (gridRows.some(r => r.id !== lastRow.id && r.id!.includes("noR-"))) {
            setShowErrorDialog(true);
            return;
        }

        if (dailyWorkloadApplications.length) {
            let rowsWithMissingEndTime = rows.filter(r => r.id !== lastRow.id && r.start && !r.end && !dailyWorkloadApplications.find(app => app.applicationData?.find(appData => appData.dailyWorkloadId === r.id && appData.newEnd)));
            if (rowsWithMissingEndTime.length > 0) {
                setShowErrorDialog(true);
                return;
            }
        }
        else if (gridRows.some(r => r.id !== lastRow.id && r.start && !r.end)) {
            setShowErrorDialog(true);
            return;
        }
        else {
            setShowErrorDialog(false);
        }
    }, [lockedForEditing, rows, dailyWorkloadApplications]);

    const fetchData = useCallback(() => {   
        fetchHsEmployee();
        fetchDailyWorkloads().then(() => fetchDailyWorkloadApps());
    }, []); 

    useEffect(() => {
        //let breaks: Break[] = breakSchedule.filter(b => b.departmentId === employee.departmentId && b.project === employee.project && b.line === employee.line);
        //setEmployeeBreaks(breaks);

        if (employee.employeeId && (!employee.project || !employee.line || !employee.station)) {
            setMissingAssignment(true);
        }

        let heads: Head[] = headList.filter(h => h.departmentId === employee.departmentId && h.project === employee.project && h.line === employee.line);
        setHeadsOfEmployee(heads);
    }, [employee, headList]);

    const setCurrentBreakIfIsOngoing = () => {
        if (employeeBreaks.length > 0) {
            let now = moment(moment().format("H:mm"), "H:mm");
            let auxBreak = {} as Break;
            employeeBreaks.forEach((element) => {
                let breakStart = moment(element.start, "H:mm");
                let breakEnd = moment(element.end, "H:mm");
                if (now.isSameOrAfter(breakStart) && now.isBefore(breakEnd)) {                    
                    auxBreak = element;
                }
            });

            setCurrentBreak(auxBreak);
        }
    }

    useEffect(() => {
        setCurrentBreakIfIsOngoing(); 
        if (employeeBreaks.length > 0) {
            window.addEventListener("visibilitychange", setCurrentBreakIfIsOngoing);
            return () => {
                window.removeEventListener("visibilitychange", setCurrentBreakIfIsOngoing);
            };
        }

       // eslint-disable-next-line
    }, [employeeBreaks]);

    useEffect(() => {
        if (rows.length) {
            setTimeout(() => apiRef.current.scrollToIndexes({ rowIndex: rows.length - 1, }), 200);  

            let lastRow = rows[rows.length - 1];
            if (lastRow.start !== null && lastRow.end === null) {
                if (lastRow.isSupport || lastRow.station !== employee.station) {
                    setWorkingStatus(2);
                }
                else {
                    setWorkingStatus(1);
                }
            }
        }
    }, [rows, employee]);  

    useEffect(() => {
        if (dailyWorkloadApplications.filter(app => app.status === ApprovalStatus.WaitingForApproval).length > 0) {
            var newDailyWorkloads: DailyWorkloadModel[] = [];
            dailyWorkloadApplications.filter(app => app.status === ApprovalStatus.WaitingForApproval).forEach((app) => {
                if (app.isGymVisit) {
                    newDailyWorkloads.push({
                        id: newGuid(),
                        day: app.day,
                        employeeId: app.employeeId,
                        departmentId: app.employeeDepartmentId,
                        workloadDepartmentId: "",
                        position: employee.position,
                        project: "",
                        line: "",
                        station: "",
                        start: "",
                        end: "",
                        worked: "",
                        overtime: "",
                        breaktime: "",
                        isSupport: false,
                        isWeekend: moment(app.day).day() === 0 || moment(app.day).day() === 6 ? true : false,
                        isHoliday: false, //TODO: call service to get if is bank holiday   
                        isGymVisit: true,
                        isLoadedFromApplication: true
                    });
                }

                let appDataWithNewDailyWorkloads = app.applicationData?.filter(appDataItem => appDataItem.dailyWorkloadId === null);
                appDataWithNewDailyWorkloads?.forEach((appDataItem) => {
                    newDailyWorkloads.push({
                        id: newGuid(),
                        day: app.day,
                        employeeId: app.employeeId,
                        departmentId: app.employeeDepartmentId,
                        workloadDepartmentId: appDataItem.newWorkloadDepartmentId,
                        position: employee.position,
                        project: appDataItem.newProject,
                        line: appDataItem.newLine,
                        station: appDataItem.newStation,
                        start: appDataItem.newStart,
                        end: appDataItem.newEnd,
                        worked: appDataItem.newWorked,
                        overtime: appDataItem.newOvertime,
                        breaktime: appDataItem.newBreaktime,
                        isSupport: appDataItem.newIsSupport!,
                        isWeekend: moment(app.day).day() === 0 || moment(app.day).day() === 6 ? true : false,
                        isHoliday: false, //TODO: call service to get if is bank holiday   
                        isGymVisit: false,
                        isLoadedFromApplication: true
                    });
                });
            });

            let rowsToHide = rows.filter(r => (r.id?.includes("noR-") || r.id?.includes("noA-")) && dailyWorkloadApplications.some(app => app.day === r.day && app.status === ApprovalStatus.WaitingForApproval));
            let newRows = sortBy(rows.filter(r => !r.isLoadedFromApplication && !rowsToHide.some(hide => hide.id === r.id)).concat(newDailyWorkloads), ["day"]);
            //console.log("new rows po konkatenaci: ", newRows);
            setRows(newRows);
        }

        if (rows.length) setTimeout(() => { showWarningDialogIfAnyError(); }, 1000);

    }, [dailyWorkloadApplications]);   

    useEffect(() => {
        if (disableFastClicking) {
            setTimeout(() => setDisableFastClicking(false), 30000);
        }
    }, [disableFastClicking]);

    const setYearAndMonth = (year: number, month: number) => {
        setYear(-1);
        setMonth(-1);

        setYear(year);
        setMonth(month);

        //if (isHead) {
        //    persistStorage.set(`DatePicker-Year`, year.toString());
        //    persistStorage.set(`DatePicker-Month`, month.toString());
        //}        
    }        
        
    const columns: GridColDef[] = useMemo(() => [
        {
            field: "day", headerName: "Day", width: 80,
            cellClassName: (params) => params.row.isWeekend ? "day-weekend--cell" : params.row.isHoliday ? "day-holiday--cell" : "",
            renderCell: (params) => {
                const dayValue = params.value;
                const rowIndex = rows.findIndex(r => r.id === params.id);
                const previousRow = rowIndex > 0 ? rows[rowIndex - 1] : null;

                return (
                    <span>{previousRow && dayValue === previousRow.day ? "" : moment(dayValue).format("D.dd")}</span>
                );
            },
        },
        {
            field: "project", headerName: "Project", width: 587,
            renderCell: (params) => {
                let apps = dailyWorkloadApplications.filter(app => app.day === params.row.day && (app.status === ApprovalStatus.Approved || app.status === ApprovalStatus.WaitingForApproval));
                let appDataItem: ApplicationData | undefined;
                let newProject = "";
                let newLine = "";
                let newStation = "";
                let newIsSupport = false;
                let lastAppWithUpdatedProject = {} as DailyWorkloadApplication;
                let lastAppWithUpdatedLine = {} as DailyWorkloadApplication;
                let lastAppWithUpdatedStation = {} as DailyWorkloadApplication;
                let lastAppWithUpdatedIsSupport = {} as DailyWorkloadApplication;
                let lastAppWithUpdatedIsGymVisit = {} as DailyWorkloadApplication;
                apps.forEach(app => {
                    appDataItem = app.applicationData!.find(appData => appData.dailyWorkloadId === params.id);
                    if (app.isGymVisit) {
                        lastAppWithUpdatedIsGymVisit = app;
                    }
                    if (appDataItem && appDataItem.oldProject !== appDataItem.newProject) {
                        newProject = appDataItem.newProject ?? "";
                        lastAppWithUpdatedProject = app;
                    }
                    if (appDataItem && appDataItem.oldLine !== appDataItem.newLine) {
                        newLine = appDataItem.newLine ?? "";
                        lastAppWithUpdatedLine = app;
                    }
                    if (appDataItem && appDataItem.oldStation !== appDataItem.newStation) {
                        newStation = appDataItem.newStation ?? "";
                        lastAppWithUpdatedStation = app;
                    }
                    if (appDataItem && appDataItem.oldIsSupport !== appDataItem.newIsSupport) {
                        newIsSupport = appDataItem.newIsSupport ?? false;
                        lastAppWithUpdatedIsSupport = app;
                    }
                });

                return (
                    <span>
                        {
                            !params.row.isLoadedFromApplication && !params.row.project && !params.row.line && !params.row.station && !params.row.isWeekend && !params.row.isHoliday && !params.row.isGymVisit &&
                            <Typography component="span" color="lightgray" sx={{ fontStyle: "italic" }}>
                                <InfoOutlined fontSize="small" sx={{ mb: "-5px" }} /> no record
                            </Typography>
                        }
                        {
                            params.row.isLoadedFromApplication && !params.row.isGymVisit &&
                            <Typography component="span" color="orange">
                                {params.row.project}!/{params.row.line}!/{params.row.station}!
                            </Typography>
                        }
                        {
                            params.row.isLoadedFromApplication && params.row.isGymVisit &&
                            <Typography component="span" color={lastAppWithUpdatedIsGymVisit?.status === ApprovalStatus.WaitingForApproval ? "orange" : "#1495F2"}>
                                    <InfoOutlined fontSize="small" sx={{ mb: "-5px" }} /> Gym
                                </Typography>
                        }
                        {
                            !params.row.isLoadedFromApplication && params.row.isGymVisit &&
                            <Typography component="span" color="#1495F2">
                                <InfoOutlined fontSize="small" sx={{ mb: "-5px" }} /> Gym
                            </Typography>
                        }
                        {
                            newProject !== "" ?
                                <Typography component="span" color={lastAppWithUpdatedProject?.status === ApprovalStatus.WaitingForApproval ? "orange" : ""}>
                                    {newProject}!/
                                </Typography>
                                : params.row.project && !params.row.isLoadedFromApplication ?
                                    <Typography component="span">
                                        {params.row.project}/
                                    </Typography>
                                    : ""
                        }
                        {
                            newLine !== "" ?
                                <Typography component="span" color={lastAppWithUpdatedLine?.status === ApprovalStatus.WaitingForApproval ? "orange" : ""}>
                                    {newLine}!/
                                </Typography>
                                : params.row.line && !params.row.isLoadedFromApplication ?
                                    <Typography component="span">
                                        {params.row.line}/
                                    </Typography>
                                    : ""
                        }
                        {
                            newStation !== "" ?
                                <Typography component="span" color={lastAppWithUpdatedStation?.status === ApprovalStatus.WaitingForApproval ? "orange" : ""}>
                                    {newStation}!
                                </Typography>
                                : params.row.station && !params.row.isLoadedFromApplication ?
                                    <Typography component="span">
                                        {params.row.station}
                                    </Typography>
                                    : ""
                        }
                        {
                            (newIsSupport || params.row.isSupport) &&
                            <span>&nbsp;&nbsp;<Support fontSize="small" htmlColor={lastAppWithUpdatedIsSupport?.status === ApprovalStatus.WaitingForApproval ? "orange" : ""} sx={{ mb: "-5px" }}/></span>
                        }
                    </span>  
                );
            }
        },
        {
            field: "start", headerName: "Start", width: 80,
            renderCell: (params) => {
                let apps = dailyWorkloadApplications.filter(app => app.day === params.row.day && (app.status === ApprovalStatus.Approved || app.status === ApprovalStatus.WaitingForApproval));
                let appDataItem;
                let newStart = "";
                let lastAppWithUpdatedStart = {} as DailyWorkloadApplication;
                apps.forEach(app => {
                    appDataItem = app.applicationData!.find(appData => appData.dailyWorkloadId === params.id && appData.oldStart !== appData.newStart);
                    if (appDataItem) {
                        newStart = appDataItem.newStart ?? "";
                        lastAppWithUpdatedStart = app;
                    }
                });

                return (
                    <React.Fragment>
                        {
                            params.row.isLoadedFromApplication && params.value &&
                            <Typography component="span" color="orange" >{params.value}!</Typography>
                        }
                        {
                            newStart !== "" ?
                                <Typography component="span" color={lastAppWithUpdatedStart?.status === ApprovalStatus.WaitingForApproval ? "orange" : ""}>{newStart}!</Typography>
                                : !params.row.isLoadedFromApplication ?
                                    params.value
                                    : ""
                        }
                    </React.Fragment>
                );
            }
        },
        {
            field: "end", headerName: "End", width: 80,
            renderCell: (params) => {
                let apps = dailyWorkloadApplications.filter(app => app.day === params.row.day && (app.status === ApprovalStatus.Approved || app.status === ApprovalStatus.WaitingForApproval));
                let appDataItem;
                let newEnd = "";
                let lastAppWithUpdatedEnd = {} as DailyWorkloadApplication;
                apps.forEach(app => {
                    appDataItem = app.applicationData!.find(appData => appData.dailyWorkloadId === params.id && appData.oldEnd !== appData.newEnd);
                    if (appDataItem) {
                        newEnd = appDataItem.newEnd ?? "";
                        lastAppWithUpdatedEnd = app;
                    }
                });

                return (
                    <React.Fragment>
                        {
                            params.row.isLoadedFromApplication && params.value &&
                            <Typography component="span" color="orange" >{params.value}!</Typography>
                        }
                        {
                            newEnd !== "" ?
                                <Typography component="span" color={lastAppWithUpdatedEnd?.status === ApprovalStatus.WaitingForApproval ? "orange" : ""}>{newEnd}!</Typography>
                                : !params.row.isLoadedFromApplication ?
                                    params.value
                                    : ""
                        }
                    </React.Fragment>
                );
            }
        },
        {
            field: "worked", headerName: "Worked", width: 90,
            renderCell: (params) => {
                let apps = dailyWorkloadApplications.filter(app => app.day === params.row.day && (app.status === ApprovalStatus.Approved || app.status === ApprovalStatus.WaitingForApproval));
                let appDataItem;
                let newWorked = "";
                let lastAppWithUpdatedWorkedHours = {} as DailyWorkloadApplication;
                apps.forEach(app => {
                    appDataItem = app.applicationData!.find(appData => appData.dailyWorkloadId === params.id && appData.oldWorked !== appData.newWorked);
                    if (appDataItem) {
                        newWorked = appDataItem.newWorked ?? "";
                        lastAppWithUpdatedWorkedHours = app;
                    }
                });                

                return (
                    <React.Fragment>
                        {
                            params.row.isLoadedFromApplication && params.value &&
                            <Typography component="span" color="orange" >{params.value}!</Typography>
                        }
                        {
                            newWorked !== "" ?
                                <Typography component="span" color={lastAppWithUpdatedWorkedHours?.status === ApprovalStatus.WaitingForApproval ? "orange" : ""}>{newWorked}!</Typography>
                                : !params.row.isLoadedFromApplication ?
                                    params.value
                                    : ""
                        }
                    </React.Fragment>
                );
            }
        },        
        {
            field: "overtime", headerName: "Overtime", width: 100,
            renderCell: (params) => {
                let apps = dailyWorkloadApplications.filter(app => app.day === params.row.day && (app.status === ApprovalStatus.Approved || app.status === ApprovalStatus.WaitingForApproval));
                let appDataItem;
                let newOvertime = "";
                let lastAppWithUpdatedOvertime = {} as DailyWorkloadApplication;
                apps.forEach(app => {
                    appDataItem = app.applicationData!.find(appData => appData.dailyWorkloadId === params.id && appData.oldOvertime !== appData.newOvertime);
                    if (appDataItem) {
                        newOvertime = appDataItem.newOvertime ?? "";
                        lastAppWithUpdatedOvertime = app;
                    }
                });                  

                return (
                    <React.Fragment>
                        {
                            params.row.isLoadedFromApplication && params.value &&
                            <Typography component="span" color="orange" >{params.value}!</Typography>
                        }
                        {
                            newOvertime !== "" ?
                                <Typography component="span" color={lastAppWithUpdatedOvertime?.status === ApprovalStatus.WaitingForApproval ? "orange" : ""}>{newOvertime}!</Typography>
                                : !params.row.isLoadedFromApplication ?
                                    params.value
                                    : ""
                        }
                    </React.Fragment>
                );
            }
        }, 
        {
            field: "breaktime", headerName: "Breaktime", width: 100,
            renderCell: (params) => {
                let apps = dailyWorkloadApplications.filter(app => app.day === params.row.day && (app.status === ApprovalStatus.Approved || app.status === ApprovalStatus.WaitingForApproval));
                let appDataItem;
                let newBreaktime = "";
                let lastAppWithUpdatedBreaktime = {} as DailyWorkloadApplication;
                apps.forEach(app => {
                    appDataItem = app.applicationData!.find(appData => appData.dailyWorkloadId === params.id && appData.oldBreaktime !== appData.newBreaktime);
                    if (appDataItem) {
                        newBreaktime = appDataItem.newBreaktime ?? "";
                        lastAppWithUpdatedBreaktime = app;
                    }
                });      
                
                return (
                    <React.Fragment>
                        {
                            params.row.isLoadedFromApplication && params.value &&
                            <Typography component="span" color="orange" >{params.value}!</Typography>
                        }
                        {
                            newBreaktime !== "" ?
                                <Typography component="span" color={lastAppWithUpdatedBreaktime?.status === ApprovalStatus.WaitingForApproval ? "orange" : ""}>{newBreaktime}!</Typography>
                                : !params.row.isLoadedFromApplication ?
                                    params.value
                                    : ""
                        }
                    </React.Fragment>
                );
            }
        },        
        {
            field: "actions", type: "actions", width: 150, align: "right",
            getActions: (params: any) => {
                let apps = dailyWorkloadApplications.filter(app => app.day === params.row.day);
                let actionArray: JSX.Element[] = [];                

                actionArray = apps.map((app) => {
                    switch (app.status) {
                        case ApprovalStatus.WaitingForApproval: return <GridActionsCellItem icon={<ManageHistory htmlColor="orange" />} label="" onClick={() => handleClickOpenApplication(app!.id!)} />
                        case ApprovalStatus.Approved: return <GridActionsCellItem icon={<PublishedWithChanges color="success" />} label="" onClick={() => handleClickOpenApplication(app!.id!)} />
                        case ApprovalStatus.Rejected: return <GridActionsCellItem icon={<HighlightOff color="error" />} label="" onClick={() => handleClickOpenApplication(app!.id!)} />
                        case ApprovalStatus.Withdrawn: return <GridActionsCellItem icon={<HighlightOff color="error" />} label="" onClick={() => handleClickOpenApplication(app!.id!)} />
                        case ApprovalStatus.Cancelled: return <GridActionsCellItem icon={<HighlightOff color="error" />} label="" onClick={() => handleClickOpenApplication(app!.id!)} />
                    }
                    return {} as JSX.Element;
                });

                if (apps.every(app => app.status !== ApprovalStatus.WaitingForApproval) && !lockedForEditing && params.row.day !== moment().format("YYYY-MM-DDT00:00:00")) {
                    // 1. There is no ongoing application where 'status' = 'WaitingForApproval'
                    // 2. The row editing is not locked
                    // 3. Cannot edit the current day (today)
                    // => Add new action 'Edit' into the actionArray
                    let icon = <Edit />;
                    let actionTitle = "Edit row"

                    if (params.id.includes("noR-") && !apps.length) {
                        // There is an attendance record in the ANeT system but no record in the SOT
                        // => Change the icon to 'Error' and the 'actionTitle'
                        icon = <Error htmlColor="#CC3259" />
                        actionTitle = "There is a record in the ANeT system, but no record here! Please complete the missing data."
                    }

                    if (params.row.start && !params.row.end && !apps.find(app => app.applicationData?.find(appData => appData.dailyWorkloadId === params.row.id && appData.newEnd))) {
                        // There is a 'start' but missing an 'end'
                        // => Change the icon to 'Error' and the 'actionTitle'
                        icon = <Error htmlColor="#CC3259" />
                        actionTitle = "There is missing the information about the 'End Time'! Please click here to complete the missing data."
                    }
                    actionArray.push(<GridActionsCellItem icon={icon} title={actionTitle} label="" onClick={() => handleClickEditDailyWorkload(params.row.day)} />);
                }

                return actionArray;
            }
        },
    ] as GridColDef[], [rows, dailyWorkloadApplications]);

    const { mutate: postNewDailyWorkload } = useMutation((request: DailyWorkloadModel) => fetchPost(`${apiUrl}/v1/dailyworkload`, request));
    const { mutate: putUpdatedDailyWorkload } = useMutation((request: DailyWorkloadModel) => fetchPut(`${apiUrl}/v1/dailyworkload/${request.id}`, request));

    const handleClickStart = () => {
        if (disableFastClicking) return;

        let now = moment();
        var newDailyWorkload: DailyWorkloadModel = {
            id: newGuid(),
            day: now.format("YYYY-MM-DDT00:00:00"),
            employeeId: employee.employeeId,
            departmentId: employee.departmentId,
            workloadDepartmentId: employee.departmentId,
            position: employee.position,
            project: employee.project,
            line: employee.line,
            station: employee.station,
            start: now.format("H:mm"),
            end: null,
            isSupport: false,
            isWeekend: now.day() === 0 || now.day() === 6 ? true : false,
            isHoliday: false,
            isGymVisit: false
        }

        let todayWorkloads = rows.filter(r => r.day === now.format("YYYY-MM-DDT00:00:00"));
        if (todayWorkloads.length === 1 && todayWorkloads[0].start === null) {
            postNewDailyWorkload(newDailyWorkload);
            let filteredRows = rows.filter(r => r.day !== now.format("YYYY-MM-DDT00:00:00"));
            setRows([...filteredRows, newDailyWorkload]);
        }
        else {
            let lastRow = rows[rows.length - 1];
            if (lastRow && lastRow.end === null) {
                lastRow.end = now.format("H:mm");
                lastRow.worked = calculateWorkedHours(lastRow, employeeBreaks);
                lastRow.breaktime = calculateBreaktime(lastRow, employeeBreaks);
                lastRow.overtime = calculateOvertime(employee, lastRow, employeeBreaks);
                putUpdatedDailyWorkload(lastRow);
                setRows([...rows.filter(r => r.id !== lastRow.id), lastRow]);
            }
            postNewDailyWorkload(newDailyWorkload);
            setRows([...rows, newDailyWorkload]);
        }

        setWorkingStatus(1);
        setDisableFastClicking(true);
    }

    const handleClickEnd = () => {
        if (disableFastClicking) return;

        let lastRow = rows[rows.length - 1];
        lastRow!.end = moment().format("H:mm");
        lastRow.worked = calculateWorkedHours(lastRow, employeeBreaks);
        lastRow.breaktime = calculateBreaktime(lastRow, employeeBreaks);
        lastRow.overtime = calculateOvertime(employee, lastRow, employeeBreaks);
        putUpdatedDailyWorkload(lastRow);
        setRows([...rows.filter(r => r.id !== lastRow.id), lastRow!]);
        setWorkingStatus(0);
    }

    const handleClickSupport = () => {
        if (disableFastClicking) return;

        setOpenStartSupportDialog(true);
    }

    const handleClickEditDailyWorkload = (dayToEdit: string) => {
        setDayToEdit(dayToEdit);
    }

    const handleClickOpenApplication = (id: string) => {
        setDailyWorkloadApplicationIdToOpen(id);
    };

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 660,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }));

    return (
        <Box>
            <Typography variant="h5" color="text.secondary" sx={{ pl: "25px", pt: "15px" }} gutterBottom component="div">
                Daily Workload
                <HtmlTooltip arrow
                    placement="right"
                    title={
                        <Typography variant="h6">
                            Need help? <br />
                            <Link target="_blank" href="http://mfgkm-wscz.wistron.com/dcc/sopwi/_layouts/PowerPoint.aspx?PowerPointView=ReadingView&PresentationId=/dcc/sopwi/QA/WI-23-006%20WSCZ-Q%20SOT%20Syst%C3%A9m%20User%20Manual%20for%20DL.pptx&Source=http%3A%2F%2Fmfgkm%2Dwscz%2Ewistron%2Ecom%2Fdcc%2Fsopwi%2FQA%2FForms%2FAllItems%2Easpx%3FPaged%3DTRUE%26p%5FSortBehavior%3D0%26p%5FFileLeafRef%3DWI%252d23%252d005%252dWSCZ%252dQ%2520How%2520to%2520set%2520up%2520SOT%2520as%25201st%2520APP%2520when%2520you%2520turn%2520on%2520the%2520computer%252epptx%26p%5FID%3D89%26PageFirstRow%3D31%26%26View%3D%7B36F51DA9%2D24F4%2D4945%2DB2FE%2D9DD69D7DC673%7D&DefaultItemOpen=1">WI-23-006 WSCZ-Q SOT System User Manual for DL</Link>
                            <br />
                            <Link target="_blank" href="http://mfgkm-wscz.wistron.com/dcc/sopwi/_layouts/PowerPoint.aspx?PowerPointView=ReadingView&PresentationId=/dcc/sopwi/QA/WI-23-006-01%20WSCZ-Q%20U%C5%BEivatelsk%C3%A1%20p%C5%99%C3%ADru%C4%8Dka%20syst%C3%A9mu%20SOT%20pro%20DL.pptx&Source=http%3A%2F%2Fmfgkm%2Dwscz%2Ewistron%2Ecom%2Fdcc%2Fsopwi%2FQA%2FForms%2FAllItems%2Easpx%3FPaged%3DTRUE%26p%5FSortBehavior%3D0%26p%5FFileLeafRef%3DWI%252d23%252d005%252dWSCZ%252dQ%2520How%2520to%2520set%2520up%2520SOT%2520as%25201st%2520APP%2520when%2520you%2520turn%2520on%2520the%2520computer%252epptx%26p%5FID%3D89%26PageFirstRow%3D31%26%26View%3D%7B36F51DA9%2D24F4%2D4945%2DB2FE%2D9DD69D7DC673%7D&DefaultItemOpen=1">WI-23-006-01 WSCZ-Q Uživatelská příručka systému SOT pro DL</Link>
                        </Typography>
                    }
                >
                    <Help color="secondary" sx={{ ml: "5px", mb: "-3px" }} />
                </HtmlTooltip>
            </Typography>
            <Box
                sx={{
                    display: "flex", width: "100%", maxWidth: "1368px", height: "82vh",
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "600",
                    },
                    "& .day-weekend--cell": {
                        fontWeight: "600",
                    },
                    "& .day-holiday--cell": {
                        fontWeight: "600",
                        color: "#d32f2f"
                    },
                }}
            >
                <DataGridPro columns={columns} rows={rows} density="compact" sx={{ padding: "10px 20px", margin: "0px 20px" }}
                    apiRef={apiRef}
                    hideFooter
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            month,
                            year,
                            setYearAndMonth,
                            showStartAndEndButtons,
                            lockedForEditing,
                            employeeId,
                            employee,
                            workingStatus,
                            currentBreak,
                            handleClickStart,
                            handleClickEnd,
                            handleClickSupport,
                            disableFastClicking,
                            missingAssignment
                        }
                    }}
                />
            </Box>

            <WarningDialog open={showErrorDialog} setOpen={setShowErrorDialog} />
            <StartSupportDialog open={openStartSupportDialog} setOpen={setOpenStartSupportDialog} employee={employee} rows={rows} setRows={setRows} currentBreak={currentBreak} breaks={employeeBreaks} setWorkingStatus={setWorkingStatus} setDisableFastClicking={setDisableFastClicking} />
            <CreateDailyWorkloadApplicationDialog dayToEdit={dayToEdit} setSetDayToEdit={setDayToEdit} rows={rows} employee={employee} headsOfEmployee={headsOfEmployee} breaks={employeeBreaks} setDailyWorkloadApplications={setDailyWorkloadApplications} />
            <DailyWorkloadApplicationDialog dailyWorkloadApplicationId={dailyWorkloadApplicationIdToOpen} setDailyWorkloadApplicationId={setDailyWorkloadApplicationIdToOpen} setDailyWorkloadApplications={setDailyWorkloadApplications} headsOfEmployee={headsOfEmployee} lockedForEditing={lockedForEditing} setDailyWorkloadApplicationIdToEdit={setDailyWorkloadApplicationIdToEdit} />
            <EditDailyWorkloadApplicationDialog dailyWorkloadApplicationId={dailyWorkloadApplicationIdToEdit} setDailyWorkloadApplicationId={setDailyWorkloadApplicationIdToEdit} setDailyWorkloadApplications={setDailyWorkloadApplications} employee={employee} headsOfEmployee={headsOfEmployee} breaks={employeeBreaks} />
        </Box>
    );
}